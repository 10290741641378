<template>
  <div class="row" style="height: 80vh">
    <div class="col-md-8 col-sm-12 h-100">
      <div class="px-2 h-100">
        <div class="card p-lg-10 p-sm-5 h-100">
          <div>
            <h2 class="text-blue">Informes programables</h2>
          </div>
          <div style="height: calc(100% - 30px); overflow-y: auto">
            <div v-for="(informe, i) in informes" :key="i">
              <div class="p-3">
                <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <div
                      class="rounded-circle bg-blue p-4 me-5 d-flex align-items-center"
                      style="width: 45px; height: 45px"
                    >
                      <img
                        alt=""
                        class="img-fluid me-5"
                        src="/media/svg/files/document_bitacora.svg"
                      />
                    </div>
                    <div style="color: #999999">
                      <h4 class="fw-bolder" style="color: #999999">
                        {{ informe.nombre }}
                      </h4>
                      <p>
                        Sensores vinculados: {{ informe.dispositivos.length }}
                      </p>
                      <p>Correos vinculados: {{ informe.correos.length }}</p>
                      <p>Creado el: {{ fecha(informe.createdAt) }}</p>
                      <p>Hora: {{ hora(informe.createdAt) }}</p>
                      <span class="text-blue">{{
                        fechaEdicion(informe.updatedAt)
                      }}</span>
                    </div>
                  </div>
                  <div class="d-flex">
                    <button
                      class="btn p-0"
                      style="height: min-content"
                      @click="descargarInforme(informe._id)"
                    >
                      <i
                        class="bi bi-arrow-bar-down mx-2 text-blue fs-2 fw-bolder"
                      ></i>
                    </button>
                    <button
                      class="btn p-0"
                      data-bs-toggle="modal"
                      data-bs-target="#modalEliminarInforme"
                      style="height: min-content"
                    >
                      <i class="bi bi-trash-fill mx-2 text-blue fs-4"></i>
                    </button>
                    <div
                      class="modal fade"
                      id="modalEliminarInforme"
                      tabindex="-1"
                      aria-labelledby="modalEliminarInformeLabel"
                      aria-hidden="true"
                    >
                      <div
                        class="modal-dialog modal-dialog-centered"
                        style="
                          border-radius: 38px;
                          max-height: 321px;
                          max-width: 375px;
                        "
                      >
                        <div class="modal-content p-5">
                          <div class="text-center my-5">
                            <h2 class="text-blue">¿Eliminar?</h2>
                          </div>
                          <div class="text-gray text-center my-5">
                            <p>
                              ¿¡Está seguro de querer eliminar este este
                              informe!?
                            </p>
                          </div>
                          <div class="text-center my-5">
                            <button
                              class="btn bg-blue rounded-pill text-white"
                              aria-label="Close"
                              data-bs-dismiss="modal"
                              @click="eliminarInforme(informe._id)"
                            >
                              Confirmar
                            </button>
                          </div>
                          <div class="text-center">
                            <button
                              type="button"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              class="btn text-blue rounded-pill"
                            >
                              Cancelar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <router-link
                      :to="{
                        name: 'editar-informe',
                        params: { id: informe._id },
                      }"
                    >
                      <i class="bi bi-pencil-fill mx-2 text-blue fs-4"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-12">
      <div class="p-2">
        <div class="card p-5">
          <router-link
            :to="{ name: 'crear-informe' }"
            class="d-flex align-items-center"
          >
            <div
              style="width: 43px; height: 43px"
              class="rounded-circle bg-blue d-flex justify-content-center align-items-center me-5"
            >
              <i class="bi bi-files text-white fs-1"></i>
            </div>
            <h4 class="fw-normal m-0">Crear informe</h4>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import reportes from "@/services/informes.service";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import es from "dayjs/locale/es";
import Swal from "sweetalert2";

export default defineComponent({
  name: "profile-informes",
  components: {},
  setup() {
    const informes = ref([]);
    const traerTodosInformes = () => {
      reportes.traerInformes().then((res) => {
        console.log(res.data);
        informes.value = res.data;
      });
    };
    const fecha = (valor) => {
      dayjs.extend(utc);
      dayjs.extend(timezone);
      dayjs.locale(es);
      return dayjs.tz(valor, "America/Bogota").format("D MMM YYYY | HH:mm");
    };
    const hora = (valor) => {
      return dayjs.tz(valor, "America/Bogota").format("HH:mm:ss");
    };
    const fechaEdicion = (valor) => {
      dayjs.extend(utc);
      dayjs.extend(timezone);
      dayjs.locale(es);
      return dayjs.tz(valor, "America/Bogota").format("dddd, MMM D - HH:mm");
    };
    const eliminarInforme = (id) => {
      reportes.eliminarInformePorId(id).then((res) => {
        console.log(res.data);
        Swal.fire(
          "Eliminado",
          "El informe ah sido eliminado con exito",
          "success"
        ).then((res) => {
          console.log(res);
          traerTodosInformes();
        });
      });
    };
    const descargarInforme = (id) => {
      reportes.descargarInformePorId(id).then((res) => {
        console.log(res);
      });
      const token = window.localStorage.getItem("id_token");
      window.open(
        `${process.env.VUE_APP_MICROSERVICE_API_INFORMES}/download/${id}?token=${token}`
      );
    };
    onMounted(() => {
      traerTodosInformes();
      setCurrentPageBreadcrumbs("Documents", ["Pages", "Profile"]);
    });
    return {
      informes,
      fecha,
      hora,
      fechaEdicion,
      eliminarInforme,
      descargarInforme,
    };
  },
});
</script>
