import axios from "axios";
import config from "../config/informes.config";

const traerInformes = () => axios.get(`${config.host}`);
const editarInformes = (id, payload) =>
  axios.put(`${config.host}/${id}`, payload);
const crearInformes = (payload) => axios.post(`${config.host}`, payload);
const verInformePorId = (id) => axios.get(`${config.host}/${id}`);
const descargarInformePorId = (id) =>
  axios.get(`${config.host}/download/${id}`);
const eliminarInformePorId = (id) => axios.delete(`${config.host}/${id}`);
const descargarInformePorDispositivo = (id, params = {}) =>
  axios.get(`${config.host}/dispositivo/${id}/pdf`, { params });

export default {
  traerInformes,
  editarInformes,
  crearInformes,
  verInformePorId,
  descargarInformePorId,
  eliminarInformePorId,
  descargarInformePorDispositivo,
};
